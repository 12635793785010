<template>
  <div class="holidays-page">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          paginated
          ref="holidays-smart-list"
          class="mt-4"
          :filter-fields="filterFields"
          :endpoint="holidaysEndpoint"
          :item-options="{ title: 'title' }"
        >
          <template v-slot:before-list v-if="month">
            <h4 class="mx-4 mt-4">
              Holidays in {{ moment(month).format("MMMM, YYYY") }}
            </h4>
          </template>
          <template v-slot:custom-filter--month>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="month"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  @click:clear="month = null"
                  label="Filter by month"
                  :value="formattedMonthString"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  readonly
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="month" type="month" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(month)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </template>
          <template v-slot:list-item="{ item: holidayItem }">
            <v-card class="d-flex mb-2 mx-3 post-card py-3">
              <v-list-item>
                <v-list-item-content>
                  <div class="black--text subtitle-1 font-weight-bold">
                    {{ holidayItem.title }}
                  </div>
                  <div class="black--text body-2"
                  v-html="TextHelper.linkify(holidayItem.description)"
                  >
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item-action>
                <p
                  class="
                    my-0
                    mr-n6
                    body-2
                    primary--text
                    font-weight-bold
                    align-self-center
                    text-center
                  "
                >
                  {{ moment(holidayItem.date).format("Do MMM") }}
                </p>
              </v-list-item-action>

              <v-list-item-icon class="pr-3 align-self-center text-center">
                <intract-drop-down-menu
                  v-if="
                    holidayItem.created_by == currentUser.id ||
                    currentUser.is_admin
                  "
                  :options-list="getDropdownOptions(holidayItem)"
                />
              </v-list-item-icon>
            </v-card>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>

    <v-fab-transition>
      <v-btn
        v-if="!isLoading && currentUser.is_admin"
        color="primary"
        class="non-focused"
        @click="holidayCreateEditSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <intract-create-edit-sheet
      persistent
      :title="
        holidayCreateEditSheet.editId ? 'Edit a Holiday' : 'Add a Holiday'
      "
      :submit-button-text="holidayCreateEditSheet.editId ? 'Edit' : 'Add'"
      :visible="holidayCreateEditSheet.visible"
      @close="
        holidayCreateEditSheet.visible = false;
        holidayCreateEditSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.holidaysViewSet"
      :edit-id="holidayCreateEditSheet.editId"
      :edit-success-message="holidayCreateEditSheet.editSuccessMessage"
      :data-object="holidayCreateEditSheet.obj"
      :create-success-message="holidayCreateEditSheet.createSuccessMessage"
      :getObject="finalOperationsOnFormObject"
      @objectCreated="holidayCreatedOrEdited"
      @updateObject="(obj) => (holidayCreateEditSheet.obj = obj)"
    />

    <!-- Delete answer confirmaton dialog  -->
    <confirmation-dialog
      title="Are you sure you want to delete this Holiday item?"
      description="This action cannot be undone"
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteHolidayItem"
      @failureCallback="showDeleteDialog = false"
    />
  </div>
</template>

<script>
import TextHelper from '@utils/text_helper';
import moment from "moment";
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";

export default {
  name: "Holidays",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
    IntractCreateEditSheet,
    IntractDropDownMenu,
  },

  data() {
    return {
      TextHelper,
      moment: moment,
      showDeleteDialog: false,
      toBeDeletedItem: null,
      holidayCreateEditSheet: {
        visible: false,
        createSuccessMessage: "Holiday item created successfully!",
        editSuccessMessage: "Holiday item edited successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
        },
      },
      // month filter
      month: null,
      modal: false,
    };
  },

  computed: {
    formattedMonthString() {
      if (!this.month) return "";
      var formatted = moment(this.month).format("MMMM, YYYY");
      // if (!this.date) return null;
      // if (this.date.length == 0) return "";
      // else if (this.date.length == 1)
      //   return moment(this.date[0]).format("ll") + " - ";
      // else
      //   return (
      //     moment(this.date[0]).format("ll") +
      //     " - " +
      //     moment(this.date[1]).format("ll")
      //   );
      return formatted;
    },

    holidaysEndpoint() {
      // return this.endpoints.HolidaysViewSet;
      var url = this.Helper.addUrlParams(this.endpoints.holidaysViewSet, [
        "ordering=date",
      ]);
      if (this.month) {
        url = this.Helper.addUrlParams(url, [
          "date__month=" + moment(this.month).format("MM"),
          "date__year=" + moment(this.month).format("YYYY"),
        ]);
      }else{
        url = this.Helper.addUrlParams(url, [`date__gte=${moment().utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format(this.Helper.dateURLFormat)}`])
      }
      return url;
    },
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 6,
          max: 255,
        },
        date: {
          fieldType: CreateEditFieldTypes.DATEPICKER,
          textModel: null,
          min: new Date(),
          label: "Date",
          required: true,
          md: 6,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description (optional)",
          md: 12,
          rows: 3,
          required: false,
          max: 3000,
        },
      };
    },
    // filterFields() {
    // },

    filterFields() {
      // console.log(this.date)
      return {
        month: {
          custom: true,
          model: null,
          items: [],
          label: "Filter by Month",
        },
      };
    },
  },

  methods: {
    async onRefresh() {
      var el = this.$refs["holidays-smart-list"];
      if (el) el.getItems();
    },

    async holidayCreatedOrEdited(holidayObj) {
      this.onRefresh();
      this.month = moment(holidayObj.date).format("YYYY-MM");
    },

    finalOperationsOnFormObject(obj) {
      // obj.created_by = obj.created_by.id;
      if (obj.date) obj.date = moment(obj.date).format();
      return obj;
    },

    getDropdownOptions(holidayItem) {
      var self = this;
      return [
        {
          value: 1,
          text: "Edit",
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.holidayCreateEditSheet.obj.created_by = holidayItem.created_by;
            self.holidayCreateEditSheet.obj.institution =
              self.currentInstitution.id;
            self.holidayCreateEditSheet.editId = holidayItem.id;
            self.holidayCreateEditSheet.visible = true;
          },
        },
        {
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedItem = holidayItem;
            self.showDeleteDialog = true;
          },
        },
      ];
    },

    async deleteHolidayItem() {
      var url = this.endpoints.holidaysViewSet + this.toBeDeletedItem.id + "/";
      this.toBeDeletedItem = null;
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Holiday item has been successfully deleted!",
          type: "success",
        });
      }
      this.onRefresh();
    },
  },

  created() {
    // this.month = moment().format("YYYY-MM");
    if (this.currentUser.is_admin) {
      this.holidayCreateEditSheet.obj.institution = this.currentInstitution.id;
      this.holidayCreateEditSheet.obj.created_by = this.currentUser.id;
    }
  },
};
</script>

<style>
</style>