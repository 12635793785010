var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"holidays-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"holidays-smart-list",staticClass:"mt-4",attrs:{"paginated":"","filter-fields":_vm.filterFields,"endpoint":_vm.holidaysEndpoint,"item-options":{ title: 'title' }},scopedSlots:_vm._u([(_vm.month)?{key:"before-list",fn:function(){return [_c('h4',{staticClass:"mx-4 mt-4"},[_vm._v(" Holidays in "+_vm._s(_vm.moment(_vm.month).format("MMMM, YYYY"))+" ")])]},proxy:true}:null,{key:"custom-filter--month",fn:function(){return [_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.month,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.month=$event},"update:return-value":function($event){_vm.month=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","label":"Filter by month","value":_vm.formattedMonthString,"prepend-inner-icon":"mdi-calendar","clearable":"","readonly":"","hide-details":"auto"},on:{"click:clear":function($event){_vm.month = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"type":"month","scrollable":""},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.month)}}},[_vm._v(" OK ")])],1)],1)]},proxy:true},{key:"list-item",fn:function(ref){
var holidayItem = ref.item;
return [_c('v-card',{staticClass:"d-flex mb-2 mx-3 post-card py-3"},[_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"black--text subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(holidayItem.title)+" ")]),_c('div',{staticClass:"black--text body-2",domProps:{"innerHTML":_vm._s(_vm.TextHelper.linkify(holidayItem.description))}})])],1),_c('v-list-item-action',[_c('p',{staticClass:"\n                  my-0\n                  mr-n6\n                  body-2\n                  primary--text\n                  font-weight-bold\n                  align-self-center\n                  text-center\n                "},[_vm._v(" "+_vm._s(_vm.moment(holidayItem.date).format("Do MMM"))+" ")])]),_c('v-list-item-icon',{staticClass:"pr-3 align-self-center text-center"},[(
                  holidayItem.created_by == _vm.currentUser.id ||
                  _vm.currentUser.is_admin
                )?_c('intract-drop-down-menu',{attrs:{"options-list":_vm.getDropdownOptions(holidayItem)}}):_vm._e()],1)],1)]}}],null,true)})],1)],1),_c('v-fab-transition',[(!_vm.isLoading && _vm.currentUser.is_admin)?_c('v-btn',{staticClass:"non-focused",attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.holidayCreateEditSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('intract-create-edit-sheet',{attrs:{"persistent":"","title":_vm.holidayCreateEditSheet.editId ? 'Edit a Holiday' : 'Add a Holiday',"submit-button-text":_vm.holidayCreateEditSheet.editId ? 'Edit' : 'Add',"visible":_vm.holidayCreateEditSheet.visible,"fields":_vm.formFields,"endpoint":_vm.endpoints.holidaysViewSet,"edit-id":_vm.holidayCreateEditSheet.editId,"edit-success-message":_vm.holidayCreateEditSheet.editSuccessMessage,"data-object":_vm.holidayCreateEditSheet.obj,"create-success-message":_vm.holidayCreateEditSheet.createSuccessMessage,"getObject":_vm.finalOperationsOnFormObject},on:{"close":function($event){_vm.holidayCreateEditSheet.visible = false;
      _vm.holidayCreateEditSheet.editId = null;},"objectCreated":_vm.holidayCreatedOrEdited,"updateObject":function (obj) { return (_vm.holidayCreateEditSheet.obj = obj); }}}),_c('confirmation-dialog',{attrs:{"title":"Are you sure you want to delete this Holiday item?","description":"This action cannot be undone","visible":_vm.showDeleteDialog,"delete-dialog":""},on:{"successCallback":_vm.deleteHolidayItem,"failureCallback":function($event){_vm.showDeleteDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }